import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewEncapsulation
} from '@angular/core';

import { ColorConstants, IconConfiguration } from '@celum/common-components';

@Component({
  selector: 'fullscreen-dialog-header',
  templateUrl: './fullscreen-dialog-header.component.html',
  styleUrls: ['./fullscreen-dialog-header.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class FullscreenDialogHeaderComponent implements OnInit {
  @HostBinding('class.fullscreen-dialog-header') public hostCls = true;

  @Input() public title: string;
  @Input() public titleClass: string;
  @Input() public actions: TemplateRef<any>;
  @Input()
  @HostBinding('class.fullscreen-dialog-header--light')
  public light: boolean;

  @Output() public readonly onClose: EventEmitter<any> = new EventEmitter<any>();

  public closeIcon: IconConfiguration = IconConfiguration.xLarge('cancel-l', '', 30);

  public close(): void {
    this.onClose.emit(null);
  }

  public ngOnInit(): void {
    this.closeIcon.color = this.light ? ColorConstants.SYSTEM_BLACK : 'white';
  }
}
