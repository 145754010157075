<fullscreen-dialog-header
  [title]="title | translate"
  [titleClass]="titleClass"
  [light]="light"
  [actions]="actions"
  (onClose)="close()">
  <ng-content select="[header-buttons]" ngProjectAs="[header-buttons]"></ng-content>
</fullscreen-dialog-header>
<loading-mask *ngIf="loading" class="fullscreen-dialog_loading-mask"></loading-mask>
<ng-content></ng-content>
